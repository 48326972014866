import { Configuration, RedirectRequest, LogLevel } from "@azure/msal-browser";
export const clientId = "57bb44f2-c035-457d-8158-9e11c5e64ee8";
export const msalConfig: Configuration = {
	auth: {
		clientId: "57bb44f2-c035-457d-8158-9e11c5e64ee8",
		authority: "https://login.microsoftonline.com/organizations",
		redirectUri: "/",
	},
	cache: {
		cacheLocation: "localStorage", // This configures where your cache will be stored
		storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
	},
	system: {
		loggerOptions: {
			logLevel: LogLevel.Trace,
		},
	},
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest: RedirectRequest = {
	scopes: [
		"User.Read",
		"Directory.Read.All",
		"Group.Read.All",
		"offline_access",
		"openid",
		"email",
		"People.Read.All",
		"Presence.Read",
		"profile",
		"User.Read.All",
		"User.ReadBasic.All",
	],
	extraQueryParameters: {
		admin_consent: "true",
	},
};
