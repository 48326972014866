import ReactDOM from "react-dom";
import * as React from "react";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { msalConfig } from "./AuthConfig";
import { PublicClientApplication } from "@azure/msal-browser";
import { Providers } from "@microsoft/mgt-element";
import { Msal2Provider } from "@microsoft/mgt-msal2-provider";
import * as lib from "@fluentui/react/lib/Icons";
lib.initializeIcons();

const BrowserRouter = React.lazy(() =>
	import("react-router-dom").then((i) => ({
		default: i.BrowserRouter,
	}))
);

const LoadingServiceProvider = React.lazy(() =>
	import(
		/* webpackChunkName: "ConfirmDialog" */ "./components/shared/LoadingDialog/LoadingDialog"
	).then((i) => ({
		default: i.LoadingServiceProvider,
	}))
);

const ConfirmationServiceProvider = React.lazy(() =>
	import(
		/* webpackChunkName: "ConfirmDialog" */ "./components/shared/ConfirmDialog/ConfirmDialog"
	).then((i) => ({
		default: i.ConfirmationServiceProvider,
	}))
);

const MsalProvider = React.lazy(() =>
	import("@azure/msal-react").then((i) => ({
		default: i.MsalProvider,
	}))
);

const App = React.lazy(() =>
	import(/* webpackChunkName: "RPMApp" */ "./App").then((i) => ({
		default: i.App,
	}))
);
const root = document.getElementById("root");
const msalInstance = new PublicClientApplication(msalConfig);
Providers.globalProvider = new Msal2Provider({ publicClientApplication: msalInstance as any });
ReactDOM.render(
	<React.StrictMode>
		<React.Suspense fallback={<Spinner label="Loading..." size={SpinnerSize.large}></Spinner>}>
			<BrowserRouter>
				<ConfirmationServiceProvider>
					<LoadingServiceProvider>
						<MsalProvider instance={msalInstance}>
							<App />
						</MsalProvider>
					</LoadingServiceProvider>
				</ConfirmationServiceProvider>
			</BrowserRouter>
		</React.Suspense>
	</React.StrictMode>,
	root
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
